.booking-list {
    font-family: "Roboto", sans-serif !important;
    font-size: 14px !important;
}

.booking-list-sec {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}

.txt-color {
    color: #3e3e52;
}

.navbar {
    flex: 1 1 20%;
    box-sizing: border-box;
    max-width: 20%;
}

.category-list {
    box-shadow: 0 7px 9px 9px #9e9e9e21;
    border: 1px solid #cccccc5c;
    padding: 10px 10px;
}

.departure-block {
    display: block;
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
}

.left-block-heading {
    margin-top: 25px;
    margin-bottom: 0px;
    color: #5f5a5ab8;
    font-size: 14px;
    font-family: "Roboto", sans-serif !important;
}

.list-base {
    padding-top: 8px;
    display: block;
    -webkit-tap-highlight-color: transparent;
}

.list-item-content {
    font-size: 12px;
    padding: 0 0px !important;
    color: #3e3e52;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    height: inherit;

}

.mr-8 {
    margin-right: 8px;
}

.MuiList-root {
    padding-top: 8px;
    display: block;
    -webkit-tap-highlight-color: transparent;
}

.MuiListItem-root {
    display: block;
    height: 33px;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    padding: 0;
    position: relative;
}   

.list-item-content .MuiSvgIcon-root {
    font-size: 18px !important;
    font-weight: bold;
}

.list-item-content .MuiCheckbox-root {
    padding-left: 0px !important;
}

.slider-hide {
    display: none !important;
}

.slider {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.slider-area {
    flex: 1 1 65%;
    box-sizing: border-box !important;
    max-width: 65%;
}

.MuiSlider-root {
    color: #cb202d  !important;
    width: 80% !important;
}

.MuiSlider-thumb::after {
    display: block;
    position: absolute;
    content: "";
    width: 2.5rem;
    height: 2.5rem;
    top: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    transform-origin: center center;
    transition: transform .1s cubic-bezier(0.25, 0.46, 0.45, 0.94),opacity .1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border-radius: 50%;
}

.MuiSlider-thumb::before {
    position: absolute;
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    left: 0.875rem;
    top: 0.875rem;
    transform: rotate(45deg);
    transition: transform .1s cubic-bezier(0.25, 0.46, 0.45, 0.94),border-radius .1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border-radius: 1.25rem;
}

.MuiSlider-rail {
    width: 100%;
    height: 2px;
    display: block;
    opacity: 1 !important;
    position: absolute;
    border-radius: 1px;
    background-color: #ccc !important;
}

.MuiSlider-track {
    background-color: #ccc !important;
}

.mr-12 {
    margin-right: 12px;
}

.slider-0 {
    flex: 1 1 9%;
    box-sizing: border-box;
    max-width: 9%;
}

.slider-10000 {
    flex: 1 1 20%;
    box-sizing: border-box;
    max-width: 20%;
}

.buslit-right {
    flex: 1 1 80%;
    box-sizing: border-box;
    max-width: 80%;
}

.right-side-block {
    padding: 20px 0px 0 20px;
    background: aliceblue;
}

.buslist-search {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}
.mb-15 {
    margin-bottom: 15px;
}
.mr-15 {
    margin-right: 15px;
}
.mt-5 {
    margin-top: 5px;
}

.buslist-search .inner-search {
    flex: 1 1 80%;
    box-sizing: border-box;
    max-width: 80%;
}

.bus-search {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.from-area {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 24%;
    
}

.from-area .MuiFormLabel-root {
    color: rgba(0,0,0,.54) !important;
}

.from-area .MuiInputBase-input {
    color: rgba(0,0,0,.54) !important;
}

.from-area .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    color: rgba(0,0,0,.54) !important;
}

.from-area .MuiSvgIcon-root {
    color: rgba(0,0,0,.54) !important;
}

.from-area .MuiInputAdornment-root {
    color: rgba(0,0,0,.54) !important;
}

.from-area .css-1laqsz7-MuiInputAdornment-root {
    color: rgba(0,0,0,.54) !important;
}

.form-button-area {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 8%;
}

.text-right {
    text-align: right;
}

.viewbus-btn {
    background: #cb202d !important;
    color: #fff !important;
    border-radius: 4px !important;
    font-size: 13px !important;
    font-weight: bold !important;
    line-height: 26px !important;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    text-transform: capitalize !important;
    padding: 0px 16px !important;
}

.mt-15 {
    margin-top: 15px;
}

.empty-div {
    flex: 1 1 10%;
    box-sizing: border-box;
    max-width: 10%;
}

.close-button {
    flex: 1 1 8%;
    box-sizing: border-box;
    max-width: 8%;
}

.sec-empty-div {
    flex: 1 1 80%;
    box-sizing: border-box;
    max-width: 80%;
}

.show-date {
    flex: 1 1 10%;
    box-sizing: border-box;
    max-width: 10%;
}

.mat-buslist-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
}

.date-display {
    vertical-align: super;
}

.light-grey {
    color: #7e7e8c;
}

.bus-details{
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}


.sorting-options {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 95%;
}

.txt-color {
    color: #3e3e52;
}

.bus-count {
    flex: 1 1 32%;
    box-sizing: border-box;
    max-width: 32%;
}

.sorting-options label strong {
    font-family: "Roboto", sans-serif !important;
}

.sort {
    flex: 1 1 7%;
    box-sizing: border-box;
    max-width: 7%;
    display: contents;
}

.sort-departure {
    flex: 1 1 10%;
    box-sizing: border-box;
    max-width: 10%;
}

.mat-sort-header-container {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.mat-sort-header-button {
    border: none;
    background: 0 0;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: inherit;
    outline: 0;
    font: inherit;
    color: currentColor;
    position: relative;
}

.mat-sort-header-arrow {
    color: #757575 !important;
    height: 10px;
    width: 10px;
    min-width: 12px;
    position: relative;
    display: flex;
    opacity: 0;
    padding-left: 6px;
}

.mat-sort-header-arrow .MuiSvgIcon-root {
    font-size: small !important;
    font-weight: 500;
}

.mat-sort-header-arrow span svg path {
    stroke: #757575;
    stroke-width: 1px;
}

.mat-sort-header-arrow:hover {
    opacity: 1;
    transform: translateY(25%);
}

.rating {
    flex: 1 1 9%;
    box-sizing: border-box;
    max-width: 9%;
}

.seats-available {
    flex: 1 1 11%;
    box-sizing: border-box;
    max-width: 11%;
    /* display: contents; */
}

.bus-details-block {}

.bus-list-block {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}

.card-outer-block {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
}

.bus-details-block .MuiCard-root {
    border: 1px solid #ccc;
    margin-top: 7px;
    padding: 0px !important;
    margin-bottom: 8px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    width: 100% !important;
}

.bus-details-block .MuiCard-root:hover {
    box-shadow: 0 16px 30px 1px rgb(0 0 0 / 10%);
    transition: 1s;
}

.bus-details-block .card-content-block {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}

.card-first-block {
    flex: 1 1 20%;
    box-sizing: border-box;
    max-width: 20%;
}

.top-block {}

.top-inner {
    flex-flow: inherit;
    box-sizing: border-box;
    display: flex;
}

.top-date-block {
    flex: 1 1 20%;
    box-sizing: border-box;
    max-width: 20%;
}
.display-date-list {
    display: flex;
    place-content: center;
    flex-direction: column;
}

.display-date-list p {
    transform: rotate(270deg);
    font-size: 11px;
}

.mb-0 {
    margin-bottom: 0px;
}

.txt-color-maroon {
    color: red;
}

.display-grid {
    flex: 1 1 20%;
    box-sizing: border-box;
    max-width: 20%;
}
.mml-20 {
    margin-left: 10px;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-8 {
    margin-bottom: 8px;
}

.mt-8 {
    margin-top: 8px;
}

.display-grid img {
    width: 15px;
}

.border-vertical {
    border-left: 3px dotted #ccc;
    height: 68%;
    display: inline-block;
}

.bus-details-block .MuiCardContent-root {
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0px;
    padding-bottom: 0px !important;
    padding-left: 0px;
    font-size: 14px
}

.time-block {
    flex: 1 1 60%;
    box-sizing: border-box;
    max-width: 60%;
}

.bus-details-block p {
    margin-top: 10px;
}

.f17 {
    font-size: 17px !important;
}

.mb-7 {
    margin-bottom: 7px !important;
}

.bus-block {
    flex: 1 1 54%;
    box-sizing: border-box;
    max-width: 54%;
}

.p-10 {
    padding: 10px 10px;
}

.mb-7 {
    margin-bottom: 7px !important;
}
.mt-2 {
    margin-top: 2px;
}

.font-weight-normal {
    font-weight: normal;
}

.card-sec-block {
    flex: 1 1 80%;
    box-sizing: border-box;
    max-width: 80%;
}

.ml-5 {
    margin-left: 5px;
}

.light-color-text {
    color: #7e7e8c;
    white-space: initial;
}

.live-track {
    flex: 1 1 40%;
    box-sizing: border-box;
    max-width: 40%;
}

.mt-7 {
    margin-top: 7px;
}

.top-block .MuiSvgIcon-root {
    width: 18px;
    height: 15px;
    margin-right: 3px;
}

.rating-block {
    flex: 1 1 22%;
    box-sizing: border-box;
    max-width: 22%;
}

.start-rating {
    background-color: #38b87c;
    color: #fff;
    padding: 3px 3px;
    border-radius: 3px;
}

.star-icon {
    font-size: 14px;
    width: 15px !important;
}

.price-block {
    flex: 1 1 24%;
    box-sizing: border-box;
    max-width: 24%;
}

.bus-details-block label, .bus-details-block label strong {
    font-size: 20px;
}

.w-100 {
    width: 100%;
}

.botton-block {
    flex: 1 1 89%;
    box-sizing: border-box;
    max-width: 89%;
}

.view-bus-details {
    transition: 1s;
    margin-top: 10px;
}

.align-right-contet {
    flex: 1 1 auto;
    text-align: right;
}

.view-bus-details a {
    text-decoration: none;
    padding: 0px 8px;
    color: #7e7e8c;
    border-right: 1px solid #e9555d;
}

.last-details {
    border-right: none !important;
}

.view-seat {
    flex: 1 1 10%;
    box-sizing: border-box;
    max-width: 10%;
}

.view-seat button {
    background: #cb202d;
    color: white;
    font-size: 13px;
    font-weight: 550;
    padding-top: 0px;
    padding-bottom: 0px;
}

.time-block small {
    color: #7e7e8c !important;
}

.view-seat .MuiButton-root:hover {
    background: #cb202d;
    color: white;
    font-size: 13px;
    font-weight: 550;
    padding-top: 0px;
    padding-bottom: 0px;
}