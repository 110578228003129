div {
    display: block;
}

.bookbus-tickets {
    width: 100%;
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 40px !important;
}

.div1 {
    flex: 1 1 42%;
    box-sizing: border-box;
    max-width: 42%;
}

.text-center-responsive {
    text-align: left;
}

.text-center {
    text-align: center;
}

.div1 .ptext {
    padding: 45px 25px 0px;
    padding-left: 70px;
    margin-left: 70px;
    text-align: left;
}

.mb-5 {
    margin-bottom: 5px;
}

.text-primary {
    color: #cb202d;
}

.fnt-30 {
    font-size: 36px;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    ;
}

h2 {
    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}


.mt-0 {
    margin-top: 0px;
}

.fn-20 {
    font-size: 20px;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.img-padding {
    padding-top: 100px;
}

.vertical-align {
    vertical-align: top;
}

.div2 {
    flex: 1 1 29%;
    box-sizing: border-box;
    max-width: 29%;
}

.mt-20 {
    margin-top: 20px;
}

.test-block {
    position: absolute;
    display: block;
    width: 32%;
}

.bus-ticket-block {
    background: #502b70 !important;
    border-top-left-radius: 45px !important;
    border-bottom-left-radius: 45px !important;
    border-bottom-right-radius: 45px !important;
    border: 1px solid #502b70 !important;
    color: #fff !important;
    padding: 40px 40px 0px 40px !important;
}

.bus-ticket-block h4 {
    margin-top: 0px;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}



.form-block {
    display: block;
    margin-top: 0em;
}

.inner-div1 {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}

.inner-div2 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
}

.MuiFormControl-root {
    width: 100%;
    margin: 16px 0px !important;
    display: block;
    text-align: left;
    font-weight: 400;

}

.MuiFormLabel-root {
    color: #fff !important;
}

.MuiFormLabel-asterisk {
    color: red
}

.MuiInputLabel-asterisk {
    color: red
}

.MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
}

.MuiFilledInput-root {
    border-bottom: 1px solid #fff !important;
}

.MuiInput-root {
    border-bottom: 1px solid #fff !important;
}

.MuiInputBase-input {
    color: #fff !important;
}

.css-1laqsz7-MuiInputAdornment-root {
    color: #fff !important;
}

.MuiInputAdornment-root {
    color: #fff !important;
}

.css-slyssw {
    color: #fff !important;
}

.div3 {
    flex: 1 1 29%;
    box-sizing: border-box;
    max-width: 29%;

}

.bg-primary {
    background: #cb202d;
    border-bottom-left-radius: 30px;
}

.bookbus-tickets .bg-primary img {
    width: 100%;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    color: #fff !important;
}

.div3 img {
    overflow-clip-margin: content-box;
    overflow: clip;
}

.mb-40 {
    margin-bottom: 35px !important;
    margin-top: 20px;
}

.search-btn1 {
    border-radius: 25px !important;
    color: #502b70 !important;
    font-weight: bold !important;
    font-size: 16px !important;
    padding: 0 27px !important;
    background-color: #fff !important;
    font-family: Roboto, 'Helvetica Neue', sans-serif !important;
    text-transform: capitalize !important;
    line-height: 36px !important;
}

.search-icon {
    height: 26px;
    width: 10px ;
    vertical-align: middle;
}