
div {
    display: block;
}

.header-block {
    min-height: 39px;
}

.box {
    padding-top: 14px;
    
}

.card-image {
    display: block;
    padding: 5px 31px;
    margin-top: 5px;
    cursor: pointer;
    overflow: clip;
}


.tickets-block {
    min-width: 300px !important;
    box-sizing: border-box;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    margin-top: auto;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    background: transparent;
    font-family: 'Roboto', "Helvetica Neue", sans-serif;
    
}


.tickets-block a span {
    color: #000000DE;
    font: 600 14px/20px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    /* font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', "Helvetica Neue", sans-serif; */
}


.login-block{
    width: 416px;
    border-top-left-radius: 30px;
    background: #cb202d;
    color: #fff;
    text-align: center;
    border: 1px solid #cb202d;
    padding: 8px 0px;
}

.login-block button {
    margin-right: 15px;
    color: #fff;
    box-sizing: border-box;
    position: relative;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}


