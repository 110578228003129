div{
    display: block;
}

.footer {
    position: static;
    bottom: 0;
}

.footer-block {
    background-color: rgba(32, 35, 41, 0.9);
    padding: 30px;
    border-top: 1px solid #f5f5f5 !important;
}

.footer-block h3 {
    color: #a7a1a1;
    font-size: 22px;
    margin-bottom: 12px;
}
 
h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.footer-inner{
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}

.footer-div1 {
    flex: 1 1 10%;
    box-sizing: border-box;
    max-width: 10%;
}

.footer-div {
    flex: 1 1 30%;
    box-sizing: border-box;
    max-width: 30%;
}

.footer-div2 {
    flex: 1 1 25%;
    box-sizing: border-box;
    max-width: 25%;
}

.footer-block a {
    color: #fff;
    display: block;
    line-height: 34px;
    font-size: 16px;
    text-decoration: none;
}

.footer-block p {
    color: #b1babf;
    line-height: 28px;
    font-size: 16px;
    margin-bottom: 5px;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.social-media {
    float: left;
    display: flex;
}

.fa{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.social-media a {
    padding: 5px 10px;
    line-height: 16px;
}

.bottom-footer-block {
    background-color: #222222;
    padding: 10px 10px;
    text-align: center;
}

.copy-right-color {
    color: #fff !important;
    font: 16px;
    font-family: Roboto,Helvetica Neue,sans-serif;
    margin: 5px 5px;
}

.bottom-footer-block p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}