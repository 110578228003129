.features {}

.features-center {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
}

.features-text {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
}

.features-heading {
    margin-top: 40px !important;
    text-align: center;
    font-size: 30px;
    color: #CB202D;
    font-weight: 700;
}

.features-content {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
}

.left-content {
    flex: 1 1 30%;
    box-sizing: border-box;
    max-width: 30%;
}

.features-inner {
    margin: 50px 0;
}

.left-side-content {}

.left-side-content-1, .right-side-content-1 {
    display: flex;
    padding-left: 30px;
    align-items: center;
}

.left-side-content-1 .icons, .right-side-content-1 .icons {
    height: 75px;
    width: 75px;
}


.left-side-content-1 h3, .right-side-content-1 h3 {
    padding-left: 30px;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

.features-mb-40 {
    margin-bottom: 40px !important;
}

.center-content {
    flex: 1 1 27%;
    box-sizing: border-box;
    max-width: 27%;
}

.right-content {
    flex: 1 1 30%;
    box-sizing: border-box;
    max-width: 30%;
    align-self: center;
}

.w-75 {
    width: 100%;
}

.center-text-content {}

.center-text-content h3 {
    font-size: 16px;
    margin-top: 1px !important;
    letter-spacing: 0.7px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.right-side-content {}

.ml-20 {
    margin-left: 40px;
}