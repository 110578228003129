.signup-block {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex !important;
    justify-content: center !important;
}

.signup-block .signup-box {
    display: flex;
}

.first-block {
    width: 400px;
    padding: 45px 20px;
    flex: 1 1 50%;
    box-sizing: border-box;
    max-width: 50%;
}

.list-item-head {
    display: flex;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
}

.MuiDialog-paperWidthSm {
    max-width: 100% !important;
}

.MuiDialog-paper::-webkit-scrollbar {
    display: none;
}


.top-header {}

.list-item-head .Mui-selected {
    padding: 0px 16px !important;
    background: red !important;
    outline: none !important;
    border: none !important;
    width: 181px !important;
    height: 49px !important;
    font-size: 16px !important;
    font-family: 'Poppins', sans-serif !important;
    color: white !important;
    font-weight: 600 !important;
}

.list-item-head .MuiButtonBase-root .MuiTab-root {
    padding: 0px 16px !important;
    background: white !important;
    outline: none !important;
    border: none !important;
    width: 181px !important;
    height: 49px !important;
    border: 1px solid #F5F6F6 !important;
    font-size: 16px !important;
    font-family: 'Poppins', sans-serif !important;
    color: #39373A !important;
    font-weight: 600 !important;
}

.MuiTab-wrapper {
    font-size: 16px !important;
    font-family: 'Poppins', sans-serif !important;
    /* color: #39373A; */
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.PrivateTabIndicator-colorSecondary-6 {
    background-color: #fff !important;
}

.second-block {
    flex: 1 1 50%;
    box-sizing: border-box;
    max-width: 50%;
}

.banner-block {
    background-color: #F13A3A;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner {
    position: relative;
}

.MuiTabs-indicator { 
    display: none;
 }

.banner .card-img {
    position: absolute;
    top: 12px;
    right: 12px;
}

.bgcard-image {
    width: 100%;
}

.test-function {
    margin-top: 38px;
    margin-bottom: -18px;
}

.test-function label {
    margin-left: 15px;
    font-size: 13px;
    font-weight: 500;
}

.form-field {
    display: inline-block;
    position: relative;
    text-align: left;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    background-color: #edeeef;

}

.list-add {}

.seltest  {
    
}

.list-add .MuiInputBase-root .MuiInputBase-input .MuiOutlinedInput-input  .MuiOutlinedInput-root .MuiInputBase-formControl {
    background: #EDEEEF !important;
    border-radius: 34px !important;
    padding: 0 !important;
    width: 360px !important;
    height: 36px !important;
    margin-top: 10px !important;
    margin-left: 20px !important;
    font-size: 12px !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    color: #98989C !important;
}

.list-add .MuiFormControl-root {
    border-radius: 34px !important;
}

/* .list-add .MuiInputBase-input {
    margin: 16px 16px 16px 0px !important;
} */

.seltest input {
    margin-left: 20px !important;
    font-size: 12px !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    color: rgba(0,0,0,.87) !important;
}

.signin-function {}
.signin-function button {
    width: 363px;
    height: 54px;
    border-radius: 40px;
    background-color: white;
    border: 2px solid #F13A3A;
    color: #1B1C20;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    display: block;
    margin: auto;
    margin-top: 20px;
    padding: 0px 16px;
}

.signin-function button:hover {
    background-color: #F13A3A;
    color: #fff;
}

.main-container {}

.list-item-first {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.input-content {
    padding: 0px;
    width: 50%;
    padding-right: 15px;
}

.input-content label {
    display: block;
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 600;
    margin-left: 15px;
}

.main-container .MuiInputBase-root .MuiInputBase-input .MuiOutlinedInput-input  .MuiOutlinedInput-root .MuiInputBase-formControl {
    background: #EDEEEF !important;
    border-radius: 34px !important;
    padding: 0 !important;
    width: 360px !important;
    height: 36px !important;
    margin-top: 10px !important;
    margin-left: 20px !important;
    font-size: 12px !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    color: #98989C !important;
}

.main-container .MuiFormControl-root {
    border-radius: 34px !important;
}

.main-container input {
    margin-left: 20px !important;
    font-size: 12px !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    color: rgba(0,0,0,.87) !important;
}


.input-content-list {
    padding: 0px;
    width: 50%;
}

.input-content-list label {
    display: block;
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 600;
    margin-left: 15px;
    opacity: 0;
}

.list-content {
    margin-top: 1px;
}

.function-new {}

.function-new label {
    opacity: 100;
}

.add-function {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.add-function label {
    margin-left: 15px;
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 600;
}

.select-sec label {
    margin-left: 15px;
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 600;
}

.select-sec .MuiInputBase-input {
    margin-left: 20px !important;
    font-size: 12px !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    color: #98989C !important;
}

.boxes {}

.boxes .mat-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Poppins';
    font-size: 13px;
}

.boxes span {
    color: #98989C;
    font-family: 'Poppins';
    font-size: 13px;
    line-height: 24px;
}

.boxes span b a {
    color: #551A8B;
    font-weight: bold;
}

.boxes .MuiSvgIcon-root {
    color: #ff4081;
}

.last-sec {}

.last-sec button {
    width: 100%;
    height: 54px;
    border-radius: 40px;
    background-color: #F13A3A;
    border: none;
    color: white;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    display: block;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
}

.last-sec button:hover {
    background-color: #ffffff;
    border: 2px solid #F13A3A;
    color: #303030;
}