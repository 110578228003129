.div-break {
    flex: 1 1 5%;
    box-sizing: border-box;
    max-width: 5%;
}

.view-seats-block {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
    flex-direction: row;
    display: flex;
}
.mt-15 {
    margin-top: 15px !important;
}

.seats-block {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 53%;
}

.seat-layout {
    background: #fff;
    padding: 10px;
    margin: 0px;
}

.text-left {
    text-align: left !important;
}

.seats-block h4, .view-seat-right h4, .seat-available-block small, .light-color-text {
    color: #7e7e8c;
    white-space: initial;
}

.lower-deck-block {
    padding-bottom: 10px;
    background: rgb(248, 248, 248);
    min-height: 200px;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
}

.mb-50 {
    margin-bottom: 50px;
}

.border-right {
   border-right: 1px solid #ccc;
}

.border-left-dark {
    border-left: 6px solid #777777;
}

.steering-img {
    width: 35px;
    height: 35px;
    opacity: 0.6;
    padding: 0px 6px;
    transform: rotate(271deg);
    margin: 18px 0%;
}

.child-2 {
    padding-left: 10px;
}

.upper-seat {
    margin-top: 15px;
    flex-flow: nowrap !important;
    display: flex;
}

.seat {
    margin-bottom: 6px;
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}

.right_seat {
    border: none;
}

.right_seat img {
    width: 34px;
    height: 22px;
    opacity: 0.7;
    padding: 0px 6px;
    margin-bottom: 5px;
}

.sleeperImage {
    width: 60px !important;
    height: 24px !important;
}

.lower-seat-block {
    width: auto;
    margin-top: 25px;
}

.lower-seat {
    margin-top: 15px;
    display: flex;
    flex-flow: nowrap !important;
    justify-content: end;
}

.view-seat-right {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 47%;
}

.p-10 {
    padding: 10px 10px;
}

.seat-head {}

.mt-0 {
    margin-top: 0px !important;
}

.seat-available-block {
    display: -webkit-box;
}

.empty-border {
    border: 1px solid #ccc;
    background: #fff;
    height: 17px;
    width: 44px;
    margin-right: 12px;
    margin-top: 0px;
}

.mr-12 {
    margin-right: 12px;
}

.bg-border {
    border: 1px solid #ccc;
    background-color: #cbcbcb;
    height: 17px;
    width: 44px;
    margin-right: 12px;
    margin-top: 0px;
}

.bg-selected {
    background: #d75f68;
    border: 1px solid #ccc;
    height: 17px;
    width: 44px;
    margin-right: 12px;
    margin-top: 0px;
}

.bg-ladies {
    background: #e7a5bc;
    border: 1px solid #ccc;
    height: 17px;
    width: 44px;
    margin-right: 12px;
    margin-top: 0px;
}

.view-seat-right .MuiCard-root {
    border: 1px solid #ccc;
    margin-top: 7px;
    padding: 0px !important;
    margin-bottom: 8px;
}

.seat-boarding {
    background: #f8f8f8;
}

.seat-boarding .MuiTab-root {
    padding: 0px;
    min-width: 50% !important;
}

.seat-boarding .MuiTab-root .MuiTab-wrapper {
    font-size: 14px !important;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    font-weight: 600 !important;
    height: 48px;
    padding: 0 24px;
    cursor: pointer;
    box-sizing: border-box;
    opacity: 1;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
    color: rgba(0,0,0,.87);
}

.seat-boarding .MuiTabs-indicator {
    background-color: red !important;
    display: flex !important;
    max-width: 50%;
    width: 100%;
    /* left: 250px !important; */
    transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.seat-boarding .tabPanel {
    padding: 5px 10px;
}

.tabPanel .tab-body {
    height: 100%;
    overflow: auto;
}

.radio-group-boarding {
    overflow-y: auto;
    height: 320px;
}

.mr-15 {
    margin-right: 15px;
}


.mt-10 {
    margin-top: 10px;
}

.m-10-5 {
    margin-top: 10px;
    margin-bottom: 5px;
}

.seat-boarding .MuiFormGroup-root {
    display: block !important;
    padding: 10px;
    font-size: 17px;
}

.radio-label {
    padding-left: 0px !important;
    font-size: 17px;
    display: flex;
    align-items: baseline;
    justify-items: center;
}

.radio-label h4 strong {
    color: #000000DE;
    font-size: 17px !important;
}

.radio-label p {
    color: #000000DE;
    font-size: 17px !important;
}

.seat-boarding .bottom-block {}

.seat-boarding .p-10 {
    padding: 10px 10px;
}

.bottom-block p {
    margin-top: 10px;
}

.bottom-block p small {
    color: #7e7e8c;
}

.ml-15 {
    margin-left: 15px;
}

.w100{
    width: 100%;
}

.continue-btn{
    background: #cb202d !important;
    color: #fff;
    border-radius: 4px !important;
    font-size: 13px;
    line-height: 26px;
    padding: 0 16px;
    border-width: 0px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.pay-block {
    border: 1px solid #ccc;
    margin-top: 7px;
    padding: 0px !important;
    margin-bottom: 8px;
    background: #f8f8f8;
}

.inner-pay-block {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}

.full-block {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
}

.txt-color {
    color: #3e3e52;
}

.half-block {
    flex: 1 1 50%;
    box-sizing: border-box;
    max-width: 50%;
}

.align-right {
    flex: 1 1 auto;
    text-align: right;
}

.half-block a strong {
    cursor: pointer;
    color: lightseagreen;
}

.mb-7 {
    margin-bottom: 7px !important;
}

.mt-7 {
    margin-top: 7px;
}

.mb-3 {
    margin-bottom: 3px;
}

.f16 {
    font-size: 16px;
}

.mb-0 {
    margin-bottom: 0px;
}

.border-top {
    border-top: 1px solid #ccc;
}

.tot-amt {
    flex: 1 1 60%;
    box-sizing: border-box;
    max-width: 60%;
}

.tot-amt-sec {
    flex: 1 1 40%;
    box-sizing: border-box;
    max-width: 40%;
}