div {
    display: block;
}

.banner-sec {
    background-color: rgba(32, 35, 41, 0.04);
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    place-content: center space-between;
    align-items: center;

}

.banner-offer-section {
    overflow-x: hidden !important;
}

.mt-50 {
    margin-top: 50px;
}

.m-20 {
    margin-bottom: 20px;
}

.banner-div1 {
    flex: 1 1 25% !important;
    box-sizing: border-box;
    max-width: 25%;
}

.mtop-40 {
    margin-top: 24px;
}

.banner-mb-40 {
    margin-bottom: 40px !important;
}

.flower {
    width: 35%;
}

.banner-right {
    flex: 1 1 25%;
    box-sizing: border-box;
    max-width: 25%;
    /* align-self: center; */
}

.banner-center {
    flex: 1 1 50%;
    box-sizing: border-box;
    max-width: 50%;
    align-self: center;
}

.banner-center-content {

}

.banner-center-content .offer {
    width: 23%;
}

.banner-center-content h5 {
    font-size: 36px;
    letter-spacing: 1px;
    font-weight: 500;
    margin-top: 12px !important;
    margin-bottom: 5px;
    color: #cb202d;
    font-family: 'Bebas Neue', cursive;
}

.mb-13 {
    margin-bottom: 13px;
}
.mt-13 {
    margin-top: 13px;
}

.banner-center-content h2 {
    font-family: 'Poppins', cursive;
    font-size: 66px;
    font-weight: 600;
    color: #cb202d;
    margin-top: -5px;
    margin-bottom: -4px;
}

.promo-code {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-promo {
    height: 28px;
    width: 14%;
    background-color: white;
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #502b70;
    text-align: left;
    padding: 2px;
    color: #999;
    border-right: none;
}

.input-promo p {
    margin: 6px auto;
    font-size: 13px;
}

.pro-btn {
    background-color: #cb202d !important;
    color: white !important;
    border-radius: 0px 4px 4px 0px !important;
}

.banner-center-content h4 {
    font-family: 'Poppins', cursive;
    font-size: 26px;
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 12px;
}

.banner-center-sec-content-1 {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.pr-35 {
    padding-right: 40px;
}

.debcard {}

.netbank {}

.upi-1{}

.debcard p, .netbank p, .upi-1 p {
    font-family: 'Poppins', cursive;
    font-size: 18px;
    font-weight: 500;
    margin: 5px auto;
    color: #a5a4a4;
}

.debcard img, .netbank img{
    width: 25% !important;
}

.upi-1 img {
    width: 68%;
}

.pr-20 {
    padding-right: 14px;
}

