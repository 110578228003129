.features-popup {}

.popup {
    display: block;
    padding: 0px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    min-width: 30vw;
    max-height: 25vw;
}

.popup .MuiCard-root {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    border-radius: 4px;
    min-width: 30vw;
    min-height: 25vw;
}

.popup .card-title-text {
    margin-bottom: 0px;
    font-size: 20px;
    display: block;
    margin-top: 0px;
    font-weight: 500;
}

.popup .card-bg-primary {
    background: #cb202d;
    color: #fff;
    padding: 0px;
}

.popup .close-button {
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 1px;
}

.popup .MuiCardHeader-action {
    margin-top: 0px;
    margin-right: 0px;
}

.close-button .MuiSvgIcon-root {
    width: 25px !important;
    height: 25px !important;
}

.popup .MuiTypography-root {
    font-size: 20px;
    font-weight: 500;
    padding-left: 8px;
}

.popup .MuiSvgIcon-fontSizeMedium {
    font-weight: 600;
}

.popup .MuiCardContent-root {
    padding: 0px 0px;
    /* height: 300px; */
    /* width: 100%; */
    /* overflow-y: scroll; */
}

.popup .content-block {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}

.mb-15 {
    margin-bottom: 15px;
}

.amenities-block {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
    height: 300px !important;
    width: 100%;
    padding-inline-start: 30px;
    padding-top: 10px;
}

.bullet-list {
    -webkit-margin-after: 0em;
    margin-block-end: 0em;
    -webkit-margin-before: 0em;
    margin-block-start: 0em;
    /* -webkit-padding-start: 30px; */
    /* padding-inline-start: 30px; */
}

.bullet-list li {
    line-height: 36px !important;
}
.item {
    font-size: 15px;
    text-align: justify;
}

.boarding-block {
    padding: 5px 28px !important;
    height: 300px;
    width: 650px;
    overflow-y: scroll;
}

.inner-board {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}

.dropping-block {
    flex: 1 1 50%;
    box-sizing: border-box;
    max-width: 50%;
}

.txt-muted {
    color: #5f5a5ab8;
}

.dropping-block ul {
    -webkit-padding-start: 0px;
}

.dropping-block ul li {
    line-height: 32px;
    list-style-type: none;
}

ul li span {
    margin-right: 10px;
    font-size: 14px;
    color: #4a4a4acc;
}

.txt-overflow {
    text-overflow: ellipsis;
    width: 77%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
}

.reviews-block {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
    height: 300px;
    width: 650px;
}

.cancel-policy {
    flex: 1 1 100%;
    box-sizing: border-box;
    margin: 10px 10px;
}

