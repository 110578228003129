.aboutus-section {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}

.aboutus {
    padding: 20px;
    background: #cccccc87;
}

.aboutus-content {
    flex: 1 1 70%;
    box-sizing: border-box;
    max-width: 70%;
}
.about-us-detail {
    margin: 0 auto;
}

.mat-card {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
    color: #cb202d;
    font-size: 19px;
    display: block;
    margin-bottom: 8px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 500;
}

.aboutus p {
    margin-bottom: 0px;
}
.mt-25 {
    margin-top: 25px !important;
}
.text-justify {
    text-align: justify;
    line-height: 26px;
}